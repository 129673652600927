import App from './App.svelte'

const app = new App({
  target: document.body,
  props: {
    name: 'Garret Harp',
    position: 'Full Stack Developer',
    topSkills: ['JavaScript', 'Node.JS', 'AWS']
  }
})

export default app
