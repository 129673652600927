<script>
  import Tailwindcss from "./Tailwindcss.svelte";

  export let name = "",
    position = "",
    topSkills = [];
</script>

<Tailwindcss />

<div class="bg-gray-900 m-0 p-0">
  <div
    class="p-5 px-10 flex flex-auto flex-col md:flex-row items-center mx-auto
    lg:w-9/12 xl:w-7/12">
    <img
      class="w-16 h-16 md:w-20 md:h-20 object-cover rounded-full"
      src="/favicon.png"
      alt="Profile" />
    <div class="flex flex-auto flex-col items-center md:items-start">
      <h1 class="font-bold text-2xl ml-5">{name}</h1>
      <h3 class="text-2xl ml-5">{position}</h3>
    </div>
    <div class="hidden md:flex flex-auto flex-col items-end">
      <h1 class="font-bold text-lg">Top Skills</h1>
      <div class="flex flex-auto flex-row">
        {#each topSkills as skill, index}
          <p class="pl-2">{skill}</p>
          {#if index !== topSkills.length - 1}
            <p class="pl-2">•</p>
          {/if}
        {/each}
      </div>
    </div>
  </div>

</div>

<div class="p-12 pt-8 mx-auto lg:w-9/12 xl:w-7/12">

  <div>
    <p class="text-lg">
      Hello! My name is {name}! I am currently a Software Engineer, and love
      working on tools that help other people. I tend to stick my eyes on web
      development, as it is what I enjoy most. I have been developing ever since
      I was 13 years old. It started out with basic HTML pages, however, as time
      went on I expanded into new languages, and frameworks.
    </p>
    <p class="text-lg pt-2">
      I currently have expierence in all the basics, HTML, CSS, and JavaScript.
      As well as frontend frameworks such as React.JS, Next.JS, Svelte, Sapper,
      and Angular. For the backend I tend to stick to Node.JS, however, I am
      always willing to learn new languages. I also have expierence in cloud
      providers like AWS, I have learned things like EC2, and Lambda for
      computational services. SNS, SQS, and SES to deliever emails,
      notifications, or job queuing. As well as mastering DynamoDB by taking
      courses from Alex DeBrie, and listening to tons of talks on Dynamo.
    </p>
    <p class="text-lg pt-2">
      Recently I have been working on a project to help streamers on
      <a
        target="_blank"
        class="text-blue-300 visited:text-blue-500"
        href="http://mixer.com">
        Mixer
      </a>
      better share their content. I did this by making a tool that allows any
      streamer to create clips called
      <a
        target="_blank"
        class="text-blue-300 visited:text-blue-500"
        href="http://smartclips.app">
        SmartClips.
      </a>
    </p>
  </div>

  <div class="pt-5 md:pt-10">
    <p class="text-lg">
      I believe that tailwind, and tailwind ui are really good tools that help
      developers create tools easier (or applications like this one!). While I
      do not believe CSS is hard, having tools like tailwind makes it that much
      easier, and faster to do the things we do. Ultimately that is what I
      strive to do, and make. I want to help others in anyway that I can, every
      second I can help save someone means a lot to me. Tailwind has helped me
      in my more recent projects, and I would love to help add onto it, and make
      it even easier for other developers to do what they do best, develop new
      things.
    </p>
    <blockquote class="pt-3 text-sm">
      Note: One of the more recent projects Tailwind (and Tailwind UI) is
      helping me on is my redesign of SmartClips.
      <a
        target="_blank"
        class="text-blue-300 visited:text-blue-500"
        href="https://preview.smartclips.app">
        https://preview.smartclips.app
      </a>
    </blockquote>
  </div>

  <div class="pt-5 md:pt-10">
    <p class="text-lg">
      I am always optimistic about what the future might hold. However, I hope
      in the future that we will be able to work together on more, and more
      tools that help make developers lifes easier, allowing them to build
      bigger and better tools. The future of this industry really excites me.
      The amount of knowledge that people talk about everyday and seeing the
      growth of everything even over the short span of 5 years is crazy to think
      about. I believe it is with technology, and us developers that we will be
      able to solve problems that right now we do not think of as possible.
    </p>
  </div>

  <div class="pt-5 md:pt-10">
    <p class="text-lg">
      I look forward to the future, and the things we can build together. If you
      have any other questions, feel free to reach me at
      <a
        class="text-blue-300 visited:text-blue-500"
        href="mailto:garretcharp@gmail.com">
        garretcharp@gmail.com.
      </a>
    </p>
  </div>

</div>
